import guest from '@/middleware/guest'

export default [
	{
		path: '/login',
		alias: '/',
		name: 'Login',
		meta: {
			title: 'menu.login'
		},
		beforeEnter: guest,
		component: () => import('@/auth/Login')
	},
	{
		path: '/forgot',
		name: 'Forgot',
		meta: {
			title: 'menu.forgot'
		},
		beforeEnter: guest,
		component: () => import('@/auth/Forgot')
	},
	{
		path: '/register',
		name: 'Register',
		meta: {
			title: 'menu.register'
		},
		beforeEnter: guest,
		component: () => import('@/auth/Register')
	},
	{
		path: '/password/:token',
		name: 'Password',
		meta: {
			title: 'menu.password'
		},
		beforeEnter: guest,
		component: () => import('@/auth/Password')
	},
	{
		path: '*',
		name: 'Error',
		meta: {
			title: 'menu.error'
		},
		component: () => import('@/errors/404')
	}
]
