import i18n from '@/language/i18n'

export function setPermissions(state, value) {
	state.permissions = value
}

export function setUser(state, value) {
	state.user = value

	if (value) {
		i18n.locale = value.language
		state.theme = value.theme
		localStorage.setItem('@stup:user', JSON.stringify(value))
		localStorage.setItem('@stup:permission', value.permission.route)
	}
}

export function setNotifications(state, value) {
	state.notifications = value
}

export function setNotificationStart(state, value) {
	state.notificationStart = value
}

export function setTotalNotifications(state, value) {
	state.totalNotifications = value
}

export function setActionUrl(state, value) {
	state.actionUrl = value
}

export function setLoadingRedirect(state, value) {
	state.loadingRedirect = value
}

export function setTheme(state, value) {
	state.theme = value
}
