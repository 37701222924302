import auth from '@/middleware/auth'

export default [
	{
		path: '/profile',
		name: 'Profile',
		icon: 'profile',
		meta: {
			title: 'menu.profile'
		},
		beforeEnter: auth,
		component: () => import('@/pages/profile/Personal')
	},
	{
		path: '/profile/password',
		name: 'Security',
		icon: 'roles',
		meta: {
			title: 'menu.security'
		},
		beforeEnter: auth,
		component: () => import('@/pages/profile/Password')
	},
	{
		path: '/profile/preferences',
		name: 'Preferences',
		icon: 'settings',
		meta: {
			title: 'menu.preferences'
		},
		beforeEnter: auth,
		component: () => import('@/pages/profile/Settings')
	}
]
