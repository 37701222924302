import { create } from 'apisauce'
import { errorToast } from '@/mixins/toast'
import router from '@/router'
import store from "@/store"

const api = create({
	baseURL: process.env.VUE_APP_BASE_API
})

api.addAsyncRequestTransform(request => () => {
	const token = localStorage.getItem('@stup:token')
	if (token) {
		request.headers['Authorization'] = `Bearer ${token}`
	}
})

api.addResponseTransform(response => {
	const token = localStorage.getItem('@stup:token')

	if (token) {
		if ([403, 401].includes(response.status)) {
			store.dispatch('user/deleteUserData')
			router.push('/')
			errorToast('Você não tem permissão para acessar este recurso')
		}
	}

	if (!response.ok) {
		throw response
	}
})

export default api
