export function setEndpointBase(state, value) {
	state.endpointBase = value
}

export function setCurrentId(state, value) {
	state.currentId = value
}

export function setCustomMethod(state, value) {
	state.customMethod = value
}
