// https://logaretm.github.io/vee-validate/guide/rules.html
import i18n from '@/language/i18n'
import { required, confirmed, length, email, alpha, alpha_num, digits, numeric, min, max } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('required', {
	...required,
	message: i18n.t('validation.required')
})

extend('email', {
	...email,
	message: i18n.t('validation.email')
})

extend('alpha', {
	...alpha,
	message: i18n.t('validation.alpha')
})

extend('alpha_num', {
	...alpha_num,
	message: i18n.t('validation.alpha_num')
})

extend('confirmed', {
	...confirmed,
	message: i18n.t('validation.password')
})

extend('length', {
	...length,
	message: i18n.t('validation.length')
})

extend('min', {
	...min,
	message: i18n.t('validation.min')
})

extend('max', {
	...max,
	message: i18n.t('validation.max')
})

extend('digits', {
	...digits,
	message: i18n.t('validation.digits')
})

extend('numeric', {
	...numeric,
	message: i18n.t('validation.numeric')
})