import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pt from '@/language/pt.json'
import en from '@/language/en.json'
import es from '@/language/es.json'

Vue.use(VueI18n)

const defaultLanguage = navigator.language || navigator.userLanguage
const messages = {
    'pt-BR': pt,
    'en-US': en,
    'es-ES': es
}

export default new VueI18n({
    locale: defaultLanguage,
    fallbackLocale: 'pt-BR',
    silentTranslationWarn: true,
    messages
})
