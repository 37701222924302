/*
	Example of use

	import { mapGetters } from 'vuex'

	this.permissionEnabled('dashboard', 'create')

	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
*/

export function permissionEnabled(state) {
	return (role, type) => {
		if (state.permissions) {
			const permission = state.permissions.find(p => p.slug === role.toLowerCase())
			return permission.role[type]
		}

		return false
	}
}

export function totalNotifications(state) {
	return state.totalNotifications
}

export function notificationStart(state) {
	return state.notificationStart
}

export function isDark(state) {
	return state.theme == 'dark'	
}

export function getPermissionId(state) {
	if (state.user) {
		return state.user.permission_id
	}

	return null
}
